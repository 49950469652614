import { styled } from "styled-components";

import Button from "components/Button";
import TextField from "components/TextField";
import { styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const SearchForm = styled.form`
    ${columnGap};
    display: grid;
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(2, [col-start] 1fr);
    grid-template-rows: auto 1fr;
    margin-top: 7rem;
    padding-bottom: 1.75rem;
    position: relative;
    row-gap: 2.1875rem;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        margin-top: 9.625rem;
        padding-bottom: 2.625rem;
        row-gap: 2.625rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 13.125rem;
        padding-bottom: 3.5rem;
        row-gap: 3.5rem;
    }
`;

export const InputField = styled(TextField)`
    &&& {
        .MuiInputBase {
            &-input {
                ${styleHeadingXL}
                height: 2.625rem;
                padding: 0.875rem;

                ${MQ.FROM_M} {
                    height: 4.375rem;
                    padding: 1.3125rem;
                }

                ${MQ.FROM_XL} {
                    height: 7rem;
                }
            }

            &-root {
                margin-top: 0;
            }
        }
    }
`;

export const SearchButton = styled(Button)`
    ${MQ.FROM_XL} {
        margin-right: 0.1875rem;
    }
`;
