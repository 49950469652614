import { InputAdornment } from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";

import {
    useBreakpoint,
    useDebounce,
    useHeader,
    useIsomorphicLayoutEffect,
} from "hooks";

import { SearchButton, SearchForm, InputField } from "./SearchField.styled";
import SearchFieldProps from "./SearchFieldProps";

const SearchField = ({
    searchPageUrl,
    predefinedValue,
    className,
    resetOnSubmit = false,
    resetOnHide = false,
    debounce = false,
    id,
    searchLabel,
    searchPlaceholder,
    component,
    displayOptions,
    identifier,
}: SearchFieldProps): ReactElement => {
    const { control, handleSubmit, reset, setValue, setFocus } = useForm();
    const history = useHistory();
    const { searchOverlayVisible } = useHeader();
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const debouncedSearchTerm = useDebounce<string | undefined>(
        searchTerm,
        2000,
    );
    const handleOnSubmit: SubmitHandler<{
        q?: string;
        resetForm?: boolean;
    }> = (data) => {
        if (!data.q?.trim()) return;
        history.push({ pathname: searchPageUrl, search: `?q=${data.q}` });
        if (data.resetForm) reset();
    };

    const triggerSubmit = () => {
        handleOnSubmit({ q: searchTerm, resetForm: false });
    };

    useEffect(() => {
        if (!searchOverlayVisible && resetOnHide) reset();
    }, [searchOverlayVisible, resetOnHide, reset]);

    useEffect(() => {
        if (searchOverlayVisible) {
            setFocus("q");
        }
    }, [searchOverlayVisible, setFocus]);

    useIsomorphicLayoutEffect(() => {
        if (debouncedSearchTerm !== undefined && debounce) triggerSubmit();
    }, [debounce, debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

    let searchIcon: string;
    switch (useBreakpoint()) {
        case "XL":
            searchIcon = "enter56";
            break;
        case "L":
            searchIcon = "enter56";
            break;
        case "M":
            searchIcon = "enter56";
            break;
        default:
            searchIcon = "enter42";
    }

    useIsomorphicLayoutEffect(() => {
        predefinedValue && setValue("q", predefinedValue);
    }, [predefinedValue, setValue]);

    const endAdornment = (
        <InputAdornment position="end">
            <SearchButton
                key={`SearchButton-${searchIcon}`}
                icon={searchIcon}
                a11y={true}
                iconOnly={true}
                type="submit"
                disabled={!searchTerm}
            >
                {searchLabel || ""}
            </SearchButton>
        </InputAdornment>
    );

    return (
        <SearchForm
            className={className}
            onSubmit={handleSubmit((data) =>
                handleOnSubmit({ ...data, resetForm: resetOnSubmit }),
            )}
            method="get"
            id={id}
        >
            <InputField
                control={control}
                elementName="q"
                predefinedValue={predefinedValue}
                placeHolder={searchPlaceholder || ""}
                inputMode="search"
                onChange={useCallback(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (_: any, value: string | number) =>
                        setSearchTerm(String(value) || ""),
                    [setSearchTerm],
                )}
                endAdornment={endAdornment}
                component={component}
                displayOptions={displayOptions}
                identifier={identifier}
            />
        </SearchForm>
    );
};

export default React.memo(SearchField);
